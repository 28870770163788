
import LogosCarousel from '~/components/LogosCarousel.vue'
export default {
  name: 'PressReviewsSlice',
  components: { LogosCarousel },
  data() {
    return {
      items: [
        {
          url: '/img/logos/forbes.png',
          alt: 'Forbes',
          text: {
            en: `“The Apple of well-being“`,
            it: `“La Apple del benessere“`,
            de: `“Das Apple des Wohlbefindens“`
          },
          dimensions: { width: 70, height: 16 }
        },
        {
          url: '/img/logos/wired.png',
          alt: 'Wired',
          text: {
            en: `“A product with a special attention to the design that eliminates pollutants“`,
            it: `“Un prodotto curato nel design che elimina gli inquinanti“`,
            de: `“Ein Produkt mit besonderer Aufmerksamkeit für das Design, das Schadstoffe eliminiert“`
          },
          dimensions: { width: 87, height: 16 }
        },
        {
          url: '/img/logos/designboom.png',
          alt: 'Design Boom',
          text: {
            en: `“Sensitize people through the use of a simple, recognizable and innovative product“`,
            it: `“Sensibilizzano le persone attraverso l'utilizzo di un prodotto semplice, riconoscibile e innovativo“`,
            de: `“Sensibilisierung der Menschen durch ein einfaches, erkennbares und innovatives Produkt“`
          },
          dimensions: { width: 107, height: 16 }
        },
        {
          url: '/img/logos/elledecor.png',
          alt: 'Elle Decor',
          text: {
            en: `“Hybrid devices that respect the environment and are able to fit harmoniously into domestic spaces and offices“`,
            it: `“Apparecchi ibridi, rispettosi dell'ambiente e in grado di inserirsi con armonia negli spazi domestici e negli uffici“`,
            de: `“Hybride Geräte, die die Umwelt schonen und sich harmonisch in Wohn- und Büroräume einfügen können“`
          },
          dimensions: { width: 39, height: 16 }
        }
        // {
        //   url: '/img/logos/businessinsider.png',
        //   alt: 'Business Insider',
        //   text: {
        //     en: `“Equipped with smart sensors so you can see in real-time how effective it is“`,
        //     it: `“Dotato di sensori intelligenti in modo da poter vedere in tempo reale quanti sia efficace“`,
        //     de: `“Ausgestattet mit intelligenten Sensoren, damit man in Echtzeit sehen kann, wie effektiv es ist“`
        //   },
        //   dimensions: { width: 56, height: 16 }
        // }
      ],
    }
  }
}
